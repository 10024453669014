<template>
  <div class="back-home-body">
        <!-- CAROUSEL-->
      
    <b-row no-gutters align-v="center">
      <b-col cols="12" md="6" style="padding:0px; ">
         <b-img style="width:100%;" :src="require('../assets/amira1.jpg')" fluid >></b-img>
      </b-col>
      <b-col cols="12" md="6" style="padding:0px; ">
         <b-img style="width:100%;" :src="require('../assets/amiracol.jpg')" fluid >></b-img>
      </b-col>
      
    </b-row>

    <b-row align-h="center">
      <b-col cols="11" md="10" lg="8" xl="8" class="mt-3">
        <b-row align="left">
          <b-col>
            <h5><b>Propiedad 101</b></h5>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-row align-h="center">
      <b-col cols="11" md="10" lg="8" xl="8" style="background-color: #E8E8E7; border-radius:25px; padding:5px;" class="mt-4 p-text">
        <br>
        <p>
          Amira District is residential complex inspired by nature. The lush common áreas are integrated with jungle gardens, hammock areas, multipurpose pavilions with sport equipment,and interconnected rooftop pools,  gym/yoga , deck areas , and outdoor parking .
        </p>
        <p>
          Amira District’s  design is based on the communion between nature and it’s integration with architectural design in a tranquil jungle setting. The building’s facade emulates the movement waves in the ocean giving the exterior a dynamic flow The design of the building’s interior emulates the inside of the jungle giving a different feel and contrast.  All of this is done to achieve a unique marriage of visual elements that mutate into a design language that pays homage to it’s location. The goal of Amira District is to provide a escape from daily life and achieve a balance between mind, body and soul .
        </p>
        <p>
          Amira District’s Phase I is 232 units  The ground floor , consists of an ecologically desinged river that will flow between the buildings and common areas , ground floor units have private swim-up pools and a gardens that connects with the mail area.  Owners will be offered a membership to the Giada Group Beach Club and be provided free transportation , kids club, cafe and restaurant , rooftop pool, gym and spa. 
        </p>
        <p>
          Unlike any other project,  Amira District has placed special  emphasis on the concept of sustainability respecting  by preserving the area’s ecosystem and biodiversity, by using materials that minimize the impact of construction and generation of waste.  Water conservation is also respected by harvesting rainwater and using it for waste wáter treatment.  Solar panels will be implemented to achieve the main use of solar power.  Wall insulation systems with double-panel PVC windows will be integrated.
        </p>
        <p>
          The concept of “ dry ” construction system is used, to ensure there are no wet binders,  making the execution time shorter, safer and more sustainable.
        </p>
        <br>
      </b-col>
    </b-row>

    <b-row align="center">
      <b-col class="mt-4" >
        <b-row align-h="center">
          <b-col cols="10" sm="8" md="4" lg="3" xl="3">
            <b-row align-h="center">
              <b-col class="mt-4" cols="12"><b-img style="height:190px" :src="require('../assets/Susyfinalmente.png')" fluid ></b-img></b-col>
              <b-col class="mb-4 p-text mt-4" cols="12">
                <h6>Contact</h6>
                <h6>info@susimacdonald.com</h6>
                <h6>+52 984 167 4506</h6>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="11" sm="11" md="8" lg="5" xl="3" class="mt-4 mb-4 " >
            <b-row align-h="left" align="left">
              <div  style="background-color:transparent; padding:17px;">
                <b-col cols="12" class="mb-3"><FormulateInput label-class="form-label-class" input-class="form-input"  label="Name" type="text" validation="" v-model="form.name"/></b-col>
                <b-col cols="12" class="mb-3"><FormulateInput label-class="form-label-class" input-class="form-input"  label="Email" type="email" validation="email" v-model="form.email"/></b-col>
                <b-col cols="12" class="mb-3"><FormulateInput label-class="form-label-class" input-class="form-input"  label="Phone" type="text" validation="" v-model="form.phone"/></b-col>
                <b-col cols="12" class="mb-3"><FormulateInput label-class="form-label-class" input-class="form-input"  label="Comment" type="textarea" validation="" v-model="form.comment"/></b-col>      
                <b-col cols="12"><b-button @click="enviarMail">Send</b-button></b-col> 
              </div>    

              <form  ref="form" >
                <input v-show="false" id="name" name="name" type="text"  v-model="form.name"> 
                <input v-show="false" id="email" name="email" type="text"  v-model="form.email"> 
                <input v-show="false" id="phone" name="phone" type="text"  v-model="form.phone"> 
                <input v-show="false" id="interest" name="interest" type="text"  v-model="form.interest"> 
                <input v-show="false" id="comment" name="comment" type="text"  v-model="form.comment"> 
              </form>
              
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

  </div>
</template>
<script>
  import { mapState} from 'vuex'
  import emailjs from '@emailjs/browser';

  export default {
    components: {
    },
    computed: {
      ...mapState({
        
      }),
    },
    data() {
      return {
        form:{
          name:"",
          email:"",
          phone:"",
          interest:["Real State"],
          comment:""
        }
      }
    },
    methods: {
      enviarMail(){
        emailjs.sendForm('service_6nsrtfm', 'template_m0kyzjk', this.$refs.form, 'W8YrTfKVZ2WwQY5A-')
        .then((result) => {
            alert('Gracias por tu registro!', result.text);
            this.form.name="";
            this.form.email="";
            this.form.phone = "";
            this.form.interest=[];
            this.form.comment = "";
        }, (error) => {
            alert('Tu registro no se completo. Intenta más tarde', error.text);
        });
      }
    },
  }
</script>
<style scoped>
@import url('../assets/snow.min.css'); 

.back-home-body{
  background-image: url('../assets/texturasusy.jpg'); 
  background-size: auto;
}

</style>